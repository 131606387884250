/* Reset default margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#sidebar {
  background-color: #0d68bd;
  width: 200px;
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
}

#main-content {
  flex: 1;
  margin-left: 200px;
  box-sizing: border-box;
}

#sidebar ul {
  list-style-type: none;
  padding: 0;
}

#sidebar ul li a {
  padding: 16px;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#sidebar ul li a svg {
  width: 26px;
  height: 26px;
  margin-right: 16px;
}

#sidebar ul li a label {
  font-size: 18px;
  cursor: pointer;
}

#sidebar ul li:hover {
  background-color: aliceblue;
  color: #0d68bd;
}

#sidebar ul li:hover a {
  background-color: aliceblue;
  color: #0d68bd;
}

@media (max-width: 768px) {

  #sidebar {
    width: 56px;
  }

  #sidebar ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 16px 4px 16px 16px;
  }

  #sidebar ul li a label {
    display: none;
  }

  #main-content {
    flex: 1;
    margin-left: 56px;
  }
}


.offcanvas .close-btn {
  color: #741029 !important; /* Set the background color */
  background: none;
  border: none; /* Remove default border */
  width: 40px; /* Set width to ensure the button is circular */
  height: 40px; /* Set height to ensure the button is circular */
  border-radius: 50%; /* Make the button circular */
  padding: 0; /* Remove default padding */
  display: flex; /* Use flexbox to center the icon */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
}

.offcanvas .close-btn:hover {
  color: #E4CEAE !important; /* Ensure color remains the same on hover/focus */
  background-color: #741029; /* Ensure background remains the same on hover/focus */
  box-shadow: none; /* Optional: remove default focus ring */
}


ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li a {
  display: block;
  color: white;
  font-size: 18px;
  padding: 8px 16px;
  text-decoration: none;
}

/* Change the link color on hover */
li a:hover {
  background-color: #E4CEAE;
  color: #741029;
  border-radius: 32px;
}



/* ---------- SIGN IN ---------- */
.sign-in__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sign-in__wrapper form {
  width: 24rem;
  max-width: 90%;
  z-index: 1;
  animation: showSignInForm 1s;
}

.sign-in__wrapper form img {
  width: 4rem;
}
